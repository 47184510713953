import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Blocks from "../components/blocks/";
import PostsList from "../components/posts/list";
import ReadMore from "../components/read-more";

export const query = graphql`
  query CategoryTemplateQuery($id: String!) {
    category: sanityCategory(id: { eq: $id }) {
      id
      title
      subtitle
      slug {
        current
      }
      poster {
        image {
          asset {
            _id
            altText
            metadata {
              lqip
              dimensions {
                width
                height
                aspectRatio
              }
            }
          }
          hotspot {
            height
            width
            x
            y
          }
        }
        caption
        source
      }
      _rawBody(resolveReferences: { maxDepth: 8 })
      color {
        hex
      }
    }
    articles: allSanityArticle(
      filter: { category: { id: { eq: $id } } }
      sort: { fields: publishDate, order: DESC }
      limit: 3
    ) {
      nodes {
        ...articleTileFields
      }
    }
    resources: allSanityResource(
      filter: { category: { id: { eq: $id } } }
      sort: { fields: publishDate, order: DESC }
      limit: 4
    ) {
      nodes {
        ...resourceTileFields
      }
    }
    events: allSanityEvent(
      filter: { category: { id: { eq: $id } }, isFuture: { eq: true } }
      sort: { fields: eventDates___start, order: ASC }
      limit: 4
    ) {
      nodes {
        ...eventTileFields
      }
    }
    upcoming_events: allSanityEvent(
      filter: { category: { id: { eq: $id } }, isFuture: { eq: true } }
      sort: { fields: eventDates___start, order: DESC }
    ) {
      nodes {
        ...eventTileFields
      }
    }
    null_events: allSanityEvent(
      sort: { fields: eventDates___start, order: ASC }
      filter: { eventDates: { start: { eq: null } } }
    ) {
      nodes {
        ...eventTileFields
      }
    }

    past_events: allSanityEvent(
      filter: { isFuture: { eq: false } }
      sort: { fields: eventDates___start, order: DESC }
    ) {
      nodes {
        ...eventTileFields
      }
    }
  }
`;

const CategoryTemplate = ({ data }) => {
  // find the bug, why it showing in all category pages when we dont use events variable.
  const category = data.category;
  // const events = data.events.nodes;
  const upcoming_events = data.upcoming_events.nodes;
  const null_events = data?.null_events?.nodes;
  const past_events = data.past_events.nodes;
  let allUpcomingEvents = [...upcoming_events, ...null_events];

  return (
    <Layout className="category" backgroundColor={category.color.hex}>
      <SEO
        title={category.title}
        description={category.subtitle}
        image={category.poster.image}
      />
      <Hero
        title={category.title}
        description={category.subtitle}
        poster={category.poster}
        color={category.color.hex}
      ></Hero>
      <div className="container container--column">
        <Blocks data={category._rawBody} />
      </div>
      <div className="container related-content">
        {category.slug.current !== "link-up" && data.articles.nodes.length > 0 && (
          <section className="articles posts-container">
            <h2>Articles</h2>
            <PostsList
              posts={data.articles.nodes}
              includeFeatured={false}
              condensed={false}
              carousel={true}
            ></PostsList>
            <ReadMore
              text="Read more articles"
              link={`/content-finder/?type=article&category=${
                category && category.slug.current
              }`}
            ></ReadMore>
          </section>
        )}

        {data.resources.nodes.length > 0 && (
          <section className="resources posts-container">
            <h2>Research</h2>
            <PostsList
              posts={data.resources.nodes}
              includeFeatured={true}
              condensed={true}
            ></PostsList>
            <ReadMore
              text="View more research"
              link={`/content-finder/?type=resource&category=${
                category && category.slug.current
              }`}
            ></ReadMore>
          </section>
        )}

        {(upcoming_events.length > 0 || null_events > 0) &&
          category.slug.current === "link-up" && (
            <>
              <section className="events posts-container">
                <h2>Upcoming events</h2>

                <PostsList
                  posts={allUpcomingEvents}
                  condensed={false}
                  fromLinkUp={true}
                ></PostsList>

                {/* <ReadMore
                text="View more events"
                link={`/content-finder/?type=event&category=${
                  category && category.slug.current
                }`}
              ></ReadMore> */}
              </section>
            </>
          )}

        {past_events.length > 0 && category.slug.current === "link-up" && (
          <>
            <section className="events posts-container">
              <h2>Past events</h2>

              <PostsList
                posts={past_events}
                includeFeatured={false}
                condensed={false}
                fromLinkUp={true}
              ></PostsList>
              {/* <ReadMore
                text="View more events"
                link={`/content-finder/?type=event&category=${
                  category && category.slug.current
                }`}
              ></ReadMore> */}
            </section>
          </>
        )}
      </div>
    </Layout>
  );
};

export default CategoryTemplate;

const upcoming_events = [
  {
    _id: "64dcb29c-2219-4658-8b1d-389afe5b588d",
    title: "Family Camp - Tasmania",
    subtitle:
      "We are excited to take our popular Family Camp to Tasmania. It will take place across two days – 17 and 18 February 2024.",
    _type: "event",
    slug: {
      current: "family-camp-tasmania",
    },
    poster: {
      image: {
        asset: {
          _id: "image-c45f9d761259363fd9ef25ccab4c22eb56c07b3e-3456x2304-jpg",
          altText: null,
          metadata: {
            lqip: "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAUGA//EACIQAAICAgICAgMAAAAAAAAAAAECAwQFEQASEyEGMUFTYf/EABYBAQEBAAAAAAAAAAAAAAAAAAMBAv/EABoRAQACAwEAAAAAAAAAAAAAAAEAAgMRQRL/2gAMAwEAAhEDEQA/AEHyutm63yASqrW4/TJKAdL/AA82fGXoqUeTyLQJAznrX7HajX3ygtZK1XreQS78g6suvXr8jiHJAWjI9wvOJ16qpbQjOvscOhj2PIy3NnZN3chhY5yr35ID+tDsDhyAt4RVtzL52PVyNkcOXzWZbM//2Q==",
            dimensions: {
              width: 3456,
              height: 2304,
              aspectRatio: 1.5,
            },
          },
        },
        hotspot: null,
      },
    },
    posterYoutube: null,
    eventDates: {
      start: "2024-02-16",
    },
    _updatedAt: "2024-01-26T08:53:51Z",
    category: {
      title: "Link Up & Events",
      slug: {
        current: "link-up",
      },
      color: {
        hex: "#ffc709",
      },
    },
    tags: [],
  },
];

const events = [
  {
    _id: "64dcb29c-2219-4658-8b1d-389afe5b588d",
    title: "Family Camp - Tasmania",
    subtitle:
      "We are excited to take our popular Family Camp to Tasmania. It will take place across two days – 17 and 18 February 2024.",
    _type: "event",
    slug: {
      current: "family-camp-tasmania",
    },
    poster: {
      image: {
        asset: {
          _id: "image-c45f9d761259363fd9ef25ccab4c22eb56c07b3e-3456x2304-jpg",
          altText: null,
          metadata: {
            lqip: "data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAUGA//EACIQAAICAgICAgMAAAAAAAAAAAECAwQFEQASEyEGMUFTYf/EABYBAQEBAAAAAAAAAAAAAAAAAAMBAv/EABoRAQACAwEAAAAAAAAAAAAAAAEAAgMRQRL/2gAMAwEAAhEDEQA/AEHyutm63yASqrW4/TJKAdL/AA82fGXoqUeTyLQJAznrX7HajX3ygtZK1XreQS78g6suvXr8jiHJAWjI9wvOJ16qpbQjOvscOhj2PIy3NnZN3chhY5yr35ID+tDsDhyAt4RVtzL52PVyNkcOXzWZbM//2Q==",
            dimensions: {
              width: 3456,
              height: 2304,
              aspectRatio: 1.5,
            },
          },
        },
        hotspot: null,
      },
    },
    posterYoutube: null,
    eventDates: {
      start: "2024-02-16",
    },
    _updatedAt: "2024-01-26T08:53:51Z",
    category: {
      title: "Link Up & Events",
      slug: {
        current: "link-up",
      },
      color: {
        hex: "#ffc709",
      },
    },
    tags: [],
  },
];
